import { disableGpuObjectLimit } from '../globals';
import { GeometryList } from './GeometryList';
import { createBufferGeometry } from "./BufferGeometry";
import { flagAttributesDirty, getFloat32Attribute } from './BufferGeometryUtils';
import { canBeMerged, copyVertexFormat } from './consolidation/Consolidation';

export class GPUGeometryList extends GeometryList {

    constructor(numObjects, is2d, disableStreaming, isUnitBoxes) {
        super(numObjects, is2d, disableStreaming, isUnitBoxes);

        this.geomid2gpuRange = [ null ];
        this.gpuGeoms = [];
        this.scratchGeoms = [];
        this.vlayoutId = 0;

                                       
                                     
                 
        //this.disableStreaming = true; // For debugging only: Disable streamingDraw completely to compare it with tug.
        // disable GPU_OBJECT_LIMIT since there are much less buffers created than there are fragments
        disableGpuObjectLimit();
                  
    }

    // extend addGeometry()
    addGeometry(geometry, numInstances, svfid) {
        // do regular addGeometry
        svfid = super.addGeometry(geometry, numInstances, svfid);

        // ignore anything with unsupported format
        geometry.ignoreForBatching = !this.canHandleGeom(geometry);
        if (geometry.ignoreForBatching || geometry.streamingDraw) {
                                           
                                                                       
                      
            return;
        }

        // do GPU part:
        // find scratch mesh
        const scratchGeom = this.getScratchGeom(geometry);

        // ensure sufficient capacity
        if (!this.hasEnoughRoom(scratchGeom, geometry)) {
            this.flushGeom(scratchGeom);
        }
        
        // append data
        let vbstride, sgVB, gVB, sgIB, gIB, iblines;
                                       
        vbstride = scratchGeom.vbstride;
        sgVB = scratchGeom.vb;
        gVB = geometry.vb;
        sgIB = scratchGeom.ib;
        gIB = geometry.ib;
        iblines = geometry.iblines;
                 
                                                                          
                                             
                                   
                                                                      
                                 
                                       
                                   
                                             
                  
        sgVB.set(gVB, scratchGeom.vCount * vbstride);
        for (let i = 0; i < gIB.length; i++) {
            sgIB[scratchGeom.iCount + i] = gIB[i] + scratchGeom.vCount;
        }
        for (let i = 0; i < iblines?.length; i++) {
            scratchGeom.iblinesShadow[scratchGeom.ilCount + i] = iblines[i] + scratchGeom.vCount;
        }

        // store mapping
        const rangeInfo = {
            vlayoutId: scratchGeom.vlayoutId,
            gpuGeomId: scratchGeom.gpuGeomId,
            iStart: scratchGeom.iCount,
            iCount: gIB.length,
            ilStart: iblines ? scratchGeom.ilCount : undefined,
            ilCount: iblines ? iblines.length : undefined,
        };
        this.geomid2gpuRange[svfid] = rangeInfo;

        // mark new data as in use
        scratchGeom.vCount += gVB.length / vbstride;
        scratchGeom.iCount += gIB.length;
                                       
        scratchGeom.vbNeedsUpdate = true;
        scratchGeom.ibNeedsUpdate = true;
                 
                                                    
                                             
                  
        if (iblines) { 
            scratchGeom.ilCount += iblines.length;
                                           
            scratchGeom.iblines = scratchGeom.iblinesShadow;    // make line index visible to renderer
            scratchGeom.iblinesNeedsUpdate = true;

            // add indexlines attribute if not there yet
            if (geometry.attributes.indexlines && !scratchGeom.attributes.indexlines) {
                scratchGeom.attributes.indexlines = geometry.attributes.indexlines;
            }
                     
                                                                 
                                                                                                 
             
                                                      
                      
        }
    }

    // HACK: extend printStats() to trigger finish()
    printStats() {
        this.finish();
        super.printStats();
    }


    finish() {
        // flush all remaining non-empty scratch geometries
        for (const scratchGeom of this.scratchGeoms) {
            if (scratchGeom.vCount > 0) {
                this.flushGeom(scratchGeom);
            }
        }

        // remove now empty scratch geometries from gpuGeoms
        for (let i = 0; i < this.gpuGeoms.length; i++) {
            const scratchGeom = this.gpuGeoms[i].pop();
            // also dispose the geometry
            scratchGeom.dispose();
        }

        // let go of all scratch geometries
        this.scratchGeoms = [];
        this.vlayoutId = 0;
    }

    canHandleGeom(geometry) {
        // can only deal with 16bit indices for simplicity (OTG and SVF never produce 32bit indices)
        let indexBytes, indexlinesBytes;
                                       
        indexBytes = geometry.attributes?.index?.bytesPerItem;
        indexlinesBytes = geometry.attributes?.indexlines?.bytesPerItem;
                 
                                                             
                                                                       
                  
        return indexBytes == 2 && (!indexlinesBytes || indexlinesBytes == 2);
    }

    getScratchGeom(geometry) {
        for (const scratchGeom of this.scratchGeoms) {
            if (canBeMerged(scratchGeom, geometry)) {
                return scratchGeom;
            }
        }

        // found new vertex layout
        const sg = this.createNewScratchGeom(geometry);
        this.scratchGeoms.push(sg);
        this.gpuGeoms[sg.vlayoutId] = [ sg ];

        return sg;
    }

    createNewScratchGeom(geometry) {
        const newGeom = createBufferGeometry();
        newGeom.vlayoutId = this.vlayoutId++;
        newGeom.gpuGeomId = 0;
        newGeom.isLines = geometry.isLines;
        newGeom.attributesKeys = geometry.attributesKeys;

        let vbstride, vb, ib;
                                       
        vbstride = geometry.vbstride;
                 
                                                                     
                                           
                  

        // create vertexbuffer for 64k vertices
        const VCAPACITY = 65536;
        vb = new Float32Array(VCAPACITY * vbstride);

        // add sufficient capacity for indices and lines
        ib = new Uint16Array(VCAPACITY * 4);
        newGeom.iblinesShadow = new Uint16Array(VCAPACITY * 2);     // line index must remain hidden until something is in it

                                       
        newGeom.attributes = Object.assign({}, geometry.attributes);
        newGeom.vbstride = vbstride;
        newGeom.vb = vb;
        newGeom.ib = ib;
                 
                                                    
                  

        // initially, everything is empty
        newGeom.vCount = 0;
        newGeom.iCount = 0;
        newGeom.ilCount = 0;

        return newGeom;
    }

    hasEnoughRoom(scratchGeometry, geometry) {
        let vbstride, sgVB, gVB, sgIB, gIB, iblines;
                                       
        vbstride = scratchGeometry.vbstride;
        sgVB = scratchGeometry.vb;
        gVB = geometry.vb;
        sgIB = scratchGeometry.ib;
        gIB = geometry.ib;
        iblines = geometry.iblines;
                 
                                                                              
                                             
                                   
                                                                      
                                 
                                           
                                   
                                             
                  

        return (scratchGeometry.vCount * vbstride + gVB.length < sgVB.length)
               && (scratchGeometry.iCount + gIB.length < sgIB.length)
               && (!iblines || scratchGeometry.ilCount + iblines.length < scratchGeometry.iblinesShadow.length);
    }

    flushGeom(geometry) {
        var finalizedGeom = createBufferGeometry();
        finalizedGeom.vlayoutId = geometry.vlayoutId;
        finalizedGeom.isLines = geometry.isLines;

                                       
        // TODO: activate to save memory once debugging is done
        //finalizedGeom.discardAfterUpload = true;
        finalizedGeom.vbstride = geometry.vbstride;
        finalizedGeom.attributes = Object.assign({}, geometry.attributes);

        // copy relevant part of the data arrays
        finalizedGeom.vb = geometry.vb.slice(0, geometry.vCount * finalizedGeom.vbstride);
        finalizedGeom.ib = geometry.ib.slice(0, geometry.iCount);
        if (geometry.ilCount) {
            finalizedGeom.iblines = geometry.iblines.slice(0, geometry.ilCount);
        }
                 
                                                                     
                                                                                         
                                                                  
                       
                               
                                                                              
         
                                                                      
                                                                                   
                               
                                                    
                                       
                    
                                  
             
         
                                                     
                                                            
                                                
         
                 
                                                     
         
                         
                                                          
         
                  

        // store: scratchgeom must remain the last entry
        this.gpuGeoms[finalizedGeom.vlayoutId].splice(-1, 0, finalizedGeom);

        // clear the scratch geometry
        geometry.vCount = 0;
        geometry.iCount = 0;
        geometry.ilCount = 0;
                                       
        geometry.iblines = null;                    // hide line index as long as it is not used - it is still accessible via iblinesShadow
        delete geometry.attributes.indexlines;   // also remove indexlines attributes for consistency
                 
                                   
                  
        ++geometry.gpuGeomId;

        return finalizedGeom;
    }

    getGpuGeometrySortKey(svfid) {
        const info = this.geomid2gpuRange[svfid];
        if (!info) {
            return 0xffffffff;
        }
        return info.vlayoutId * 0x10000 + info.gpuGeomId;
    }

    getGpuGeometry(svfid, addGroup) {
        // get the GPU buffer containing svfid and set up the correct range
        const info = this.geomid2gpuRange[svfid];
        if (!info) {
            return null;
        }

        const gpuGeom = this.gpuGeoms[info.vlayoutId][info.gpuGeomId];
        if (!gpuGeom) {
            return null;
        }

        // add a group for compatibility
        if (addGroup) {
            gpuGeom.groups = gpuGeom.groups || [];
            const group = gpuGeom.groups[0] = gpuGeom.groups[0] || { materialIndex: 0, index: 0 };
            group.start = info.iStart;
            group.count = info.iCount;
            group.edgeStart = info.ilStart;
            group.edgeCount = info.ilCount;
        }

                                       
        gpuGeom.starts = [];
        gpuGeom.counts = [];
        gpuGeom.edgeStarts = [];
        gpuGeom.edgeCounts = [];
                  
        
        return gpuGeom;
    }

    addGpuGroup(gpuGeom, svfid) {
        const info = this.geomid2gpuRange[svfid];
        if (!info) { return; }

        gpuGeom.starts.push(info.iStart * 2);                               // measured in BYTES!
        gpuGeom.counts.push(info.iCount);
        if (info.ilCount>0) {
            gpuGeom.edgeStarts.push(info.ilStart * 2);                    // measured in BYTES!
            gpuGeom.edgeCounts.push(info.ilCount);
        }
    }

    hasEdges(svfid) {
        const info = this.geomid2gpuRange[svfid];
        if (!info) { return false; }

        return info.ilCount>0;
    }
}